<template>
  <div class="page__wrapper">
    <ag-grid
      ref="summaryReportGrid"
      pagination
      style="width:100%;height:100%;"
      :column-defs="columnDefs"
      :row-data.sync="tableData"
      :framework-components="frameworkComponents"
      :pagination-page-size="tablePageInfo.pageSize"
      :grid-options="gridOptions"
    >
      <!-- 查询条件 -->
      <simple-form
        v-model="searchModel"
        :form-field="searchFormField"
      >
        <template slot="tableOperation">
          <el-button @click="handleDataSearch">
            {{ $t('operation.search') }}
          </el-button>
          <el-button
            type="primary"
            @click="handleExportClick"
          >
            {{ $t('summary_report.dataSummary') }}
          </el-button>
        </template>
      </simple-form>
    </ag-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import pageMixins from '@/components/base/simple-table/mixin'

const BASEURL = {
  list: '/jlr/tt/selfExaminationData/list',
  office: '/system/office/list',
  export: '/jlr/tt/selfExaminationData/exportAll'
}

/** 自定义操作列 */
const Operation = Vue.extend({
  inject: ['handleDataSearch', 'handleExportClick'],
  template: `<div style="height:100%;text-align:center;">
      <el-tooltip effect="light" :content="$t('operation.download')">
        <el-button v-permisson="'summary_report:export'" type="text" style="font-size:14px;" icon="el-icon-download" @click="handleExportClick(params)" />
      </el-tooltip>
    </div>`
})

export default {
  name: 'SummaryReport',
  mixins: [pageMixins],
  data () {
    return {
      searchModel: { period: this.$moment().subtract(1, 'months').format('YYYYMM'), bizStatus: 'V1' },
      tableData: [],
      dealerList: [],
      groupList: [],
      isViewFlag: false,
      isShowUploader: false,
      isShowDetailDialog: false,
      isShowAnomalyDialog: false,
      gridOptions: {},
      dealerLoading: false,
      groupLoading: false,
      frameworkComponents: {}
    }
  },
  provide () {
    return {
      handleDataSearch: this.handleDataSearch,
      handleExportClick: this.handleExportClick
    }
  },
  computed: {
    searchFormField () {
      return [
        {
          prop: 'period',
          type: 'Datepicker',
          col: { xs: 5, sm: 5, md: 5 },
          label: this.$t('thirdParty_reportExport.month'),
          component: { clearable: false, type: 'month', valueFormat: 'yyyyMM' }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 5, sm: 5, md: 5 },
          component: { optionList: this.$getDictList('sys_office_region'), clearable: true },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 5, sm: 5, md: 5 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          prop: 'dealer',
          label: this.$t('dealer_finance.dealer'),
          type: 'SearchableInput',
          col: { xs: 5, sm: 5, md: 5 },
          component: {
            remoteMethod: this.getDealerList,
            loading: this.dealerLoading,
            optionList: this.dealerList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getDealerList()
            }
          }
        },
        {
          prop: 'bizStatus',
          type: 'Select',
          col: { xs: 5, sm: 5, md: 5 },
          component: { clearable: false, optionList: this.$getDictList('tt_self_examination_data_head_biz_status') },
          label: this.$t('thirdParty_reportExport.status')
        },
        {
          prop: 'version',
          type: 'Input',
          col: { xs: 5, sm: 5, md: 5 },
          component: { clearable: true },
          label: this.$t('dealer_finance.version')
        },
        { slotName: 'tableOperation', col: { xs: 14, sm: 14, md: 14 }, style: { textAlign: 'right' }, labelWidth: '0' }
      ]
    },
    columnDefs () {
      return [
        // {
        //   width: 40,
        //   pinned: 'left',
        //   suppressSizeToFit: true,
        //   headerCheckboxSelection: true,
        //   checkboxSelection: true
        // },
        {
          headerName: this.$t('dealer_finance.dealerName'),
          field: 'dealer.name',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.dealerCode'),
          field: 'dealer.code',
          pinned: 'left',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.area'),
          field: 'region',
          valueFormatter: params => this.$getDictLabel({ type: 'sys_office_region', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.group'),
          field: 'dealerParent',
          valueFormatter: params => params.data.dealerParent ? (params.data.dealerParent.name || '') : '',
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            return (valueA && valueB) ? valueA.name.localeCompare(valueB.name) : -1
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.version'),
          field: 'version',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.cycle'),
          field: 'period',
          valueFormatter: params => params.value ? this.$moment(params.value, 'YYYYMM').format('YYYY年MM月') : '',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.reporter'),
          field: 'compiler',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.reporterPosition'),
          field: 'compilerPosition',
          minWidth: 160
        },
        // {
        //   headerName: this.$t('dealer_finance.reporterPhone'),
        //   field: 'compilerPhone',
        //   minWidth: 160
        // },
        {
          headerName: this.$t('dealer_finance.reporterEmail'),
          field: 'compilerMail',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.status'),
          field: 'bizStatus',
          valueFormatter: params => this.$getDictLabel({ type: 'tt_self_examination_data_head_biz_status', value: params.value }),
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.isSubmit'),
          field: 'isSubmit',
          valueFormatter: params => this.$getDictLabel({ type: 'yes_no', value: params.value }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.submitDate'),
          field: 'submitDate',
          minWidth: 200
        }
        // {
        //   headerName: this.$t('field.operation'),
        //   suppressSizeToFit: true,
        //   width: 80,
        //   pinned: 'right',
        //   cellRenderer: 'Operation'
        // }
      ]
    }
  },
  beforeMount () {
    this.frameworkComponents = { Operation: Operation }
  },
  mounted () {
    this.handleDataSearch()
  },
  methods: {
    handleDataSearch () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'dealerParent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    getDealerList (name) {
      this.dealerLoading = true
      const params = { filter: [{ left: 'category', operate: '=', right: 'sys_office_category_3' }] }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'code', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.dealerList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.dealerLoading = false
        })
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_2' }]
      }
      if (name) {
        params.filter.push({
          left: { left: 'name', operate: 'like', right: name },
          operate: 'or',
          right: { left: 'nameEn', operate: 'like', right: name }
        })
      }
      this.$axios
        .post(BASEURL.office, params)
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    },
    handleExportClick () {
      const params = Object.assign({}, this.$utils.parseQueryCondition(this.searchModel, this.searchFormField))
      if (this.searchModel.dealer) {
        params.filter.push({ left: 'dealer.id', operate: '=', right: this.searchModel.dealer.id })
      }
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'dealerParent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.export, params)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.exportSuccess') })
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
  }
</style>
